import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
// import VueGtag from "vue-gtag";
import Ads from "vue-google-adsense";

Vue.config.productionTip = false;

Vue.use(require("vue-script2"));
Vue.use(Ads.Adsense);
// Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense);
// Vue.use(Ads.AutoAdsense, { adClient: 'ca-pub-1497018985243834', isNewAdsCode: false })

// Vue.use(
//   VueGtag,
//   {
//     config: { id: "G-P8MGNRQD52" },
//     appName: "Popmystic",
//   },
//   router
// );

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
