<template>
  <v-app
    style="
      background-attachment: fixed;
      background-image: linear-gradient(to bottom, #6750a4, #6750a4a9);
    "
  >
    <v-overlay :opacity="1" :value="loading">
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
    <v-app-bar app color="primary">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-img
        style="cursor: pointer"
        height="43"
        max-width="164"
        @click="goHome()"
        :src="require('./assets/popmysticLogo.png')"
      />
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" temporary app fixed>
      <v-list nav>
        <v-list-group :value="false" no-action>
          <template v-slot:activator>
            <v-list-item-title>MLA</v-list-item-title>
          </template>
          <v-list-item
            v-for="(page, index) in mlaPages"
            :key="index"
            router-link
            :to="page.routerLink"
          >
            <v-list-item-title v-text="page.title"></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="false" no-action>
          <template v-slot:activator>
            <v-list-item-title>APA</v-list-item-title>
          </template>
          <v-list-item
            v-for="(page, index) in apaPages"
            :key="index"
            router-link
            :to="page.routerLink"
          >
            <v-list-item-title v-text="page.title"></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="false" no-action>
          <template v-slot:activator>
            <v-list-item-title>Chicago</v-list-item-title>
          </template>
          <v-list-item
            v-for="(page, index) in chicagoPages"
            :key="index"
            router-link
            :to="page.routerLink"
          >
            <v-list-item-title v-text="page.title"></v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item router-link to="/faq">
          <v-list-item-title>FAQs</v-list-item-title>
        </v-list-item>
        <v-list-item router-link to="/disclaimers">
          <v-list-item-title>Disclaimers</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main style="margin-bottom: 30px">
      <router-view />
    </v-main>
    <cookie-law>
      <div slot="message">
        Pop Mystic uses cookies -
        <router-link to="/disclaimers">Details</router-link>
      </div>
    </cookie-law>
    <v-footer style="background-color: #c9cafc" class="justify-center" padless>
      <v-card
        flat
        tile
        color="#C9CAFC"
        class="lighten-1 white--text text-center"
      >
        <v-card-text style="padding-top: 36px">
          <v-btn
            href="https://www.instagram.com/popmysticdotcom"
            target="_blank"
            icon
            style="padding-right: 18px; color: black"
          >
            <v-icon size="36px"> mdi-instagram</v-icon>
          </v-btn>
          <v-btn
            href="https://twitter.com/popmysticdotcom"
            target="_blank"
            icon
            style="padding-left: 18px; color: black"
          >
            <v-icon size="36px"> mdi-twitter</v-icon>
          </v-btn>
        </v-card-text>
        <v-card-text class="pt-0">
          <v-img
            contain
            max-height="50px"
            src="./assets/popmysticGraphic.svg"
          />
        </v-card-text>
        <v-card-text class="white--text pt-0">
          <router-link to="/disclaimers" class="fontDark">
            <span class="fontDark"> DISCLAIMERS </span>
          </router-link>
        </v-card-text>

        <v-card-text class="fontDark pt-0">
          {{ new Date().getFullYear() }} By <strong>Popmystic</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import router from "./router";
import { mapState } from "vuex";
import CookieLaw from "vue-cookie-law";

export default {
  name: "App",
  methods: {
    goHome() {
      router.push("/");
    },
  },
  data: () => ({
    drawer: false,
    group: [],
    mlaPages: [
      {
        title: "Cite Movies",
        routerLink: "/mla/movies",
      },
      {
        title: "Cite TV Shows",
        routerLink: "/mla/tv",
      },
      {
        title: "Cite Youtube Videos",
        routerLink: "/mla/youtube",
      },
    ],
    apaPages: [
      {
        title: "Cite Movies",
        routerLink: "/apa/movies",
      },
      {
        title: "Cite TV Shows",
        routerLink: "/apa/tv",
      },
      {
        title: "Cite Youtube Videos",
        routerLink: "/apa/youtube",
      },
    ],
    chicagoPages: [
      {
        title: "Cite Movies",
        routerLink: "/chicago/movies",
      },
      {
        title: "Cite TV Shows",
        routerLink: "/chicago/tv",
      },
      {
        title: "Cite Youtube Videos",
        routerLink: "/chicago/youtube",
      },
    ],
  }),
  components: {
    CookieLaw,
  },
  computed: mapState({
    loading: "loading",
  }),
};
</script>

<style scoped>
@import "../node_modules/typeface-roboto/index.css";

.v-application a {
  color: #5b5ea6 !important;
}

@media (min-width: 1904px) {
  .container {
    max-width: 900px;
  }
}

@media (min-width: 1264px) {
  .container {
    max-width: 900px;
  }
}

.v-application .primary--text {
  color: #5b5ea6 !important;
}

.Cookie--popmystic {
  background: #f1f1f1;
  color: black;
  padding: 1.25em;
}

.Cookie--popmystic .Cookie__button--decline {
  background: transparent;
  padding: 0.625em 3.125em;
  color: darken(#fcfbc9, 50%);
  border: 0;
  font-size: 1em;
}

.Cookie--popmystic .Cookie__button--decline:hover {
  padding: 0.625em 3.125em;
  color: darken(#fcfbc9, 50%);
  border: 0;
  font-size: 1em;
  background: darken(#fcfbc9, 15%);
}

.Cookie--popmystic .Cookie__button {
  background: #fcfbc9;
  color: black;
  padding: 0.625em 3.125em;
  border: 0;
  font-size: 1em;
}

.Cookie--popmystic .Cookie__button:hover {
  color: black;
  padding: 0.625em 3.125em;
  border: 0;
  font-size: 1em;

  background: darken(#fcfbc9, 10%);
}

.Cookie--popmystic .Cookie__content {
  color: black;
}

.Cookie--batman {
  background: black;
  color: yellow;

  > .Cookie__button {
    background: black;
    color: yellow;
  }

  > .Cookie__content {
    color: yellow;
  }

  > .Cookie__message {
    color: yellow;
  }
}

.fontDark {
  color: #454545;
  text-decoration: none;
}
</style>
