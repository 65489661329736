import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store/index";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      meta: {
        title: "Pop Mystic - Search Movie Quotes and TV Scripts",
        metaTags: [
          {
            name: "description",
            content: "Pop Mystic - Search Movie Quotes and TV Scripts",
          },
          {
            name: "og:description",
            content: "Pop Mystic - Search Movie Quotes and TV Scripts",
          },
        ],
      },
      path: "/",
      name: "Home",
      component: () => import("../views/Home.vue"),
    },
    {
      path: "/searchResults",
      name: "Search Results",
      component: () => import("../views/SearchResults.vue"),
      beforeEnter: async (to, from, next) => {
        if (!to.query.phrase) {
          next("/");
          return;
        }
        await store.dispatch("performSearch", {
          searchParams: {
            searchPhrase: to.query.phrase,
            title: to.query.title,
            keyPerson: to.query.keyPerson,
            startYear: to.query.startYear,
            endYear: to.query.endYear,
            mediaType: to.query.mediaType,
            selectedGenres: to.query.genres ? to.query.genres.split(",") : "",
            page: to.query.page ? parseInt(to.query.page) : 1,
          },
        });
        next();
      },
    },
    {
      path: "/movieDetails",
      name: "Movie Details Page",
      component: () => import("../views/MovieDetailsPage.vue"),
      beforeEnter: async (to, from, next) => {
        if (!from.name) {
          if (!to.query.film || !to.query.phrases) {
            next("/");
            return;
          }
          await store.dispatch("movieDetailsDirectSearch", {
            imdbId: to.query.film,
            phrases: to.query.phrases.split(","),
          });
        }
        next();
      },
    },
    {
      meta: {
        title:
          "How to Cite Movies from DVDs, Streaming Services, etc. in MLA format",
        metaTags: [
          {
            name: "description",
            content:
              "A guide to citing movies from DVDs, Streaming Services, etc. in MLA format.",
          },
          {
            name: "og:description",
            content:
              "A guide to citing movies from DVDs, Streaming Services, etc. in MLA format.",
          },
        ],
      },
      path: "/mla/movies",
      name: "Citing Movies in MLA",
      component: () => import("../views/static-content/MlaMoviePage.vue"),
    },
    {
      meta: {
        title:
          "How to Cite Episodes from a Television Series or Streaming Series (like Netflix) in MLA Format",
        metaTags: [
          {
            name: "description",
            content:
              "How to Cite Episodes from a Television Series or Streaming Series (like Netflix) in MLA Format",
          },
          {
            name: "og:description",
            content:
              "How to Cite Episodes from a Television Series or Streaming Series (like Netflix) in MLA Format",
          },
        ],
      },
      path: "/mla/tv",
      name: "Citing TV in MLA",
      component: () => import("../views/static-content/MlaTVPage.vue"),
    },
    {
      meta: {
        title: "How to Cite YouTube Videos in MLA Format",
        metaTags: [
          {
            name: "description",
            content: "How to Cite YouTube Videos in MLA Format",
          },
          {
            name: "og:description",
            content: "How to Cite YouTube Videos in MLA Format",
          },
        ],
      },
      path: "/mla/youtube",
      name: "Citing Youtube in MLA",
      component: () => import("../views/static-content/MlaYoutubePage.vue"),
    },
    {
      meta: {
        title:
          "How to Cite Movies from DVDs, Streaming Services, etc. in APA format",
        metaTags: [
          {
            name: "description",
            content:
              "How to Cite Movies from DVDs, Streaming Services, etc. in APA format",
          },
          {
            name: "og:description",
            content:
              "How to Cite Movies from DVDs, Streaming Services, etc. in APA format",
          },
        ],
      },
      path: "/apa/movies",
      name: "Citing Movies in APA",
      component: () => import("../views/static-content/ApaMoviePage.vue"),
    },
    {
      meta: {
        title:
          "How to Cite Episodes from a Broadcast Television Series or Streaming Series (like Netflix) in APA Format",
        metaTags: [
          {
            name: "description",
            content:
              "How to Cite Episodes from a Broadcast Television Series or Streaming Series (like Netflix) in APA Format",
          },
          {
            name: "og:description",
            content:
              "How to Cite Episodes from a Broadcast Television Series or Streaming Series (like Netflix) in APA Format",
          },
        ],
      },
      path: "/apa/tv",
      name: "Citing TV in APA",
      component: () => import("../views/static-content/ApaTVPage.vue"),
    },
    {
      meta: {
        title: "How to Cite YouTube Videos in APA Format",
        metaTags: [
          {
            name: "description",
            content: "How to Cite YouTube Videos in APA Format",
          },
          {
            name: "og:description",
            content: "How to Cite YouTube Videos in APA Format",
          },
        ],
      },
      path: "/apa/youtube",
      name: "Citing Youtube in APA",
      component: () => import("../views/static-content/ApaYoutubePage"),
    },
    {
      meta: {
        title: "How to Cite Movies from DVDs, Web, etc. in Chicago format",
        metaTags: [
          {
            name: "description",
            content:
              "How to Cite Movies from DVDs, Web, etc. in Chicago format",
          },
          {
            name: "og:description",
            content:
              "How to Cite Movies from DVDs, Web, etc. in Chicago format",
          },
        ],
      },
      path: "/chicago/movies",
      name: "Citing Movies in Chicago",
      component: () => import("../views/static-content/ChicagoMoviePage"),
    },
    {
      meta: {
        title:
          "How to Cite Episodes from a Broadcast Television Series or Streaming Series in Chicago Format",
        metaTags: [
          {
            name: "description",
            content:
              "How to Cite Episodes from a Broadcast Television Series or Streaming Series in Chicago Format",
          },
          {
            name: "og:description",
            content:
              "How to Cite Episodes from a Broadcast Television Series or Streaming Series in Chicago Format",
          },
        ],
      },
      path: "/chicago/tv",
      name: "Citing TV in Chicago",
      component: () => import("../views/static-content/ChicagoTVPage"),
    },
    {
      meta: {
        title: "How to Cite YouTube Videos in Chicago Format",
        metaTags: [
          {
            name: "description",
            content: "How to Cite YouTube Videos in Chicago Format",
          },
          {
            name: "og:description",
            content: "How to Cite YouTube Videos in Chicago Format",
          },
        ],
      },
      path: "/chicago/youtube",
      name: "Citing Youtube in Chicago",
      component: () => import("../views/static-content/ChicagoYoutubePage"),
    },
    {
      meta: {
        title: "Disclaimers",
        metaTags: [
          {
            name: "description",
            content: "Cookie and terms of service disclaimers for Popmystic",
          },
          {
            name: "og:description",
            content: "Cookie and terms of service disclaimers for Popmystic",
          },
        ],
      },
      path: "/disclaimers",
      name: "Disclaimers",
      component: () => import("../views/static-content/DisclaimersPage"),
    },
    {
      meta: {
        title: "FAQ",
        metaTags: [
          {
            name: "description",
            content: "Frequently asked questions",
          },
          {
            name: "og:description",
            content: "Frequently asked questions",
          },
        ],
      },
      path: "/faq",
      name: "Frequently Asked Questions",
      component: () => import("../views/static-content/FAQPage.vue"),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  console.log(to.matched);
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  console.log(nearestWithTitle);
  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  } else {
    document.title = "Pop Mystic - Search Movie Quotes and TV Scripts";
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
