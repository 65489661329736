import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
const esb = require("elastic-builder");
const elasticsearch = require("elasticsearch");
const AWS = require("aws-sdk");

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    searchParams: {
      mediaType: "both",
    },
    searchResults: {},
    movieDetails: {},
    loading: false,
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setSearchParams(state, searchParams) {
      state.searchParams = searchParams;
    },
    setSearchResults(state, searchResults) {
      if (this.state.searchResults.scrollId) {
        this.state.searchResults.hits = this.state.searchResults.hits.concat(
          searchResults.hits
        );
        this.state.searchResults.scrollId = searchResults.scrollId;
      } else {
        state.searchResults = searchResults;
      }
    },
    setMovieDetails(state, movieDetails) {
      state.movieDetails = movieDetails;
    },
  },
  actions: {
    async performSearch(context, payload) {
      if (!this.state.searchResults.scrollId) {
        context.commit("setLoading", true);
      }
      let searchParams = payload.searchParams;
      context.commit("setSearchParams", searchParams);
      let hits = await advancedQuery_OpenSearch(
        payload.searchParams,
        this.state.searchResults
      );
      await context.commit("setSearchResults", hits);
      context.commit("setLoading", false);
      return Promise.resolve();
    },
    async movieDetailsDirectSearch(context, payload) {
      let movieDetails = await movieDetails_openSearch(payload);
      context.commit("setMovieDetails", movieDetails);
    },
    clearSearchParams(context) {
      context.commit("setSearchParams", {});
    },
    clearSearchResults(context) {
      context.commit("setSearchResults", []);
    },
    goToMovieDetails(context, payload) {
      context.commit("setMovieDetails", payload.movieDetails);
      router.push({
        path: "/movieDetails",
        query: {
          film: payload.movieDetails.imdbId,
          phrases: payload.movieDetails.resultPhrases
            .map((phrase) => phrase.phrase_id)
            .join(),
        },
      });
    },
  },
});

async function movieDetails_openSearch(payload) {
  AWS.config.update({
    region: "us-east-2",
    credentials: new AWS.Credentials(
      process.env.VUE_APP_AWS_KEY,
      process.env.VUE_APP_SECRET_KEY
    ),
  });
  let client = new elasticsearch.Client({
    hosts: [
      "https://search-os-test-1-cbsko4pn73sfzmavlfynu6tqru.us-east-2.es.amazonaws.com",
    ],
    log: "trace",
    connectionClass: require("http-aws-es"),
    // ssl:{ rejectUnauthorized: false },
    apiVersion: "7.1", // use the same version of your Elasticsearch instance
  });

  const bq = esb.boolQuery().must(esb.termQuery("imdbId", payload.imdbId));
  const requestBody = esb.requestBodySearch().query(bq);

  let opensearchRes = await client.search({
    index: "phrases",
    type: "phrase",
    body: requestBody.toJSON(),
  });
  opensearchRes = opensearchRes.hits.hits[0];

  let neighborPhrases = [];
  let resultPhrases = [];
  payload.phrases
    .sort(
      (a, b) => parseInt(a.split("-")[1], 10) - parseInt(b.split("-")[1], 10)
    )
    .forEach((phrase_id) => {
      let temp = [];
      let phraseIndex = parseInt(phrase_id.split("-")[1], 10);
      const is0Index =
        parseInt(opensearchRes._source.phrase[0].phrase_id.split("-")[1]) === 0;
      !is0Index ? temp.push(opensearchRes._source.phrase[phraseIndex - 5]) : "";
      temp.push(opensearchRes._source.phrase[phraseIndex - 4]);
      temp.push(opensearchRes._source.phrase[phraseIndex - 3]);
      temp.push(opensearchRes._source.phrase[phraseIndex - 2]);
      temp.push(opensearchRes._source.phrase[phraseIndex - 1]);
      temp.push(opensearchRes._source.phrase[phraseIndex]);
      temp.push(opensearchRes._source.phrase[phraseIndex + 1]);
      temp.push(opensearchRes._source.phrase[phraseIndex + 2]);
      temp.push(opensearchRes._source.phrase[phraseIndex + 3]);
      is0Index ? temp.push(opensearchRes._source.phrase[phraseIndex + 4]) : "";
      neighborPhrases.push(temp);

      resultPhrases.push({
        ...temp[4],
        poster: getPosterUrl(
          opensearchRes._source.seriesId
            ? opensearchRes._source.seriesId
            : opensearchRes._source.imdbId
        ),
      });
    });
  opensearchRes._source.phrase = neighborPhrases;

  let movieDetails = {
    ...opensearchRes._source,
    _id: opensearchRes._id,
    _score: opensearchRes._score,
    resultPhrases: resultPhrases,
  };
  movieDetails = changeTimeToHHMMSS(movieDetails);

  return movieDetails;
}

let maxResultScore = 0;
async function advancedQuery_OpenSearch(searchParams, prevResults) {
  AWS.config.update({
    region: "us-east-2",
    credentials: new AWS.Credentials(
      process.env.VUE_APP_AWS_KEY,
      process.env.VUE_APP_SECRET_KEY
    ),
  });
  let client = new elasticsearch.Client({
    hosts: [
      "https://search-os-test-1-cbsko4pn73sfzmavlfynu6tqru.us-east-2.es.amazonaws.com",
    ],
    log: "trace",
    connectionClass: require("http-aws-es"),
    // ssl:{ rejectUnauthorized: false },
    apiVersion: "7.1", // use the same version of your Elasticsearch instance
  });

  const bq = esb.boolQuery();

  esb.boolQuery();

  bq.must(
    esb
      .nestedQuery()
      .scoreMode("max")
      .path("phrase")
      .innerHits(esb.innerHits().size(100))
      .query(esb.matchQuery("phrase.text", searchParams.searchPhrase))
  );

  const exactMatches = searchParams.searchPhrase.match(/"([^"]*)"/gm);
  if (exactMatches) {
    exactMatches.forEach((em) => {
      bq.must(
        esb
          .nestedQuery()
          .path("phrase")
          .query(esb.matchPhraseQuery("phrase.text", em))
      );
    });
  }

  if (searchParams.title) {
    bq.must(esb.matchQuery("title", searchParams.title));
    bq.must(esb.matchPhraseQuery("title", searchParams.title));
  }
  if (searchParams.keyPerson) {
    bq.must(
      esb.multiMatchQuery(
        ["actors", "director", "writtenBy"],
        searchParams.keyPerson
      )
    );
  }
  if (searchParams.selectedGenres.length) {
    bq.filter(esb.termsQuery("genre", searchParams.selectedGenres));
  }
  if (searchParams.startYear) {
    bq.filter(esb.rangeQuery("year").gte(searchParams.startYear));
  }
  if (searchParams.endYear) {
    bq.filter(esb.rangeQuery("year").lte(searchParams.endYear));
  }
  if (searchParams.mediaType === "tv") {
    bq.must(esb.existsQuery("seriesId"));
  } else if (searchParams.mediaType === "movie") {
    bq.mustNot(esb.existsQuery("seriesId"));
  }

  const requestBody = esb
    .requestBodySearch()
    .query(bq)
    .sort(esb.sort("_score"))
    .sort(esb.sort("imdbId", "asc"));

  let opensearchRes;
  if (prevResults.scrollId) {
    opensearchRes = await client.scroll({
      scroll: "5m",
      scrollId: prevResults.scrollId,
    });
  } else {
    opensearchRes = await client.search({
      index: "phrases",
      type: "phrase",
      body: requestBody.toJSON(),
      scroll: "5m",
      // from: searchParams.page ? (searchParams.page - 1) * 10 : 0,
      size: 11,
    });
    maxResultScore =
      opensearchRes.hits.hits[0]?.inner_hits?.phrase?.hits?.max_score || 0;
  }

  if (opensearchRes.hits.total.value === 0) {
    return [];
  }

  // if (!maxResultScore) {
  //   maxResultScore =
  //     opensearchRes.hits.hits[0].inner_hits.phrase.hits.max_score;
  // }

  let searchResults = {
    totalResults: opensearchRes.hits.total.value,
    scrollId: opensearchRes._scroll_id,
    hits: opensearchRes.hits.hits
      .sort(
        (a, b) =>
          b.inner_hits.phrase.hits.max_score -
          a.inner_hits.phrase.hits.max_score
      )
      .filter(
        (hit) => hit.inner_hits.phrase.hits.max_score > maxResultScore / 4
      )
      .map((h) => {
        let neighborPhrases = [];
        h.inner_hits.phrase.hits.hits
          .filter((ih) => ih._score > maxResultScore / 2)
          .sort((a, b) => a._source.end - b._source.end)
          .forEach((ih) => {
            let temp = [];
            let phraseIndex = parseInt(ih._source.phrase_id.split("-")[1], 10);
            const is0Index =
              parseInt(h._source.phrase[0].phrase_id.split("-")[1]) === 0;
            !is0Index ? temp.push(h._source.phrase[phraseIndex - 5]) : "";
            temp.push(h._source.phrase[phraseIndex - 4]);
            temp.push(h._source.phrase[phraseIndex - 3]);
            temp.push(h._source.phrase[phraseIndex - 2]);
            temp.push(h._source.phrase[phraseIndex - 1]);
            temp.push(h._source.phrase[phraseIndex]);
            temp.push(h._source.phrase[phraseIndex + 1]);
            temp.push(h._source.phrase[phraseIndex + 2]);
            temp.push(h._source.phrase[phraseIndex + 3]);
            is0Index ? temp.push(h._source.phrase[phraseIndex + 4]) : "";
            neighborPhrases.push(temp);
          });
        h._source.phrase = neighborPhrases;

        return {
          ...h._source,
          _id: h._id,
          _score: h._score,
          resultPhrases: h.inner_hits.phrase.hits.hits
            .filter((ih) => ih._score > maxResultScore / 2)
            .map((ih) => {
              return {
                poster: h._source.seriesId
                  ? getPosterUrl(h._source.seriesId)
                  : getPosterUrl(ih._source.phrase_id),
                ...ih._source,
                score: ih._score,
              };
            }),
        };
      }),
  };
  for (let i = searchResults.hits.length - 1; i >= 0; --i) {
    if (!searchResults.hits[i].resultPhrases.length) {
      searchResults.hits.splice(i, 1);
      continue;
    }

    searchResults.hits[i] = changeTimeToHHMMSS(searchResults.hits[i]);
  }
  return searchResults;
}

function changeTimeToHHMMSS(hit) {
  for (let j = 0; j < hit.phrase.length; ++j) {
    let phrases = hit.phrase[j];
    for (let k = 0; k < phrases.length; ++k) {
      let phrase = phrases[k];
      if (!phrase) continue;
      if (typeof phrase.start === "string") continue;
      let totalSeconds = parseInt(phrase.start);
      let hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      let minutes = Math.floor(totalSeconds / 60);
      let seconds = totalSeconds % 60;
      let start = "";
      if (hours) {
        start += hours.toString().padStart(2, "0") + ":";
      } else {
        start += "00:";
      }
      if (minutes) {
        start += minutes.toString().padStart(2, "0") + ":";
      }
      start += seconds.toString().padStart(2, "0");
      phrase.start = start;
    }
  }
  return hit;
}

function getPosterUrl(phrase_id) {
  //TODO: This API doesn't seem very complete, so we might need to swap it to an OMDB call and then use the poster_link result as the poster link
  return phrase_id.includes("-")
    ? "https://img.omdbapi.com/?i=" +
        phrase_id.split("-")[0] +
        "&apikey=50b41be8"
    : "https://img.omdbapi.com/?i=" + phrase_id + "&apikey=50b41be8";
}
